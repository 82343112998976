import { useEffect } from 'react'
import { useTranslation } from 'next-i18next'

import { WEB_CONTAINER_VERTICAL_SPACE } from '@knauf-group/ct-designs/themeConfigs/constants'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import { RTLIconStyles } from '@knauf-group/ct-designs/utils/utils'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'

import type { WindowWithOneTrust } from './OneTrustCookieManager.types'

export const showCookieInfoPopup = () => {
  const windowWithOneTrust: WindowWithOneTrust = window
  windowWithOneTrust?.OneTrust?.ToggleInfoDisplay()
}

// TODO: Replace this temp Cookie Page detection with a better solution
const OneTrustCookieManager: React.FC = () => {
  const { t } = useTranslation('cms', { keyPrefix: 'common.cookies' })
  const theme = useTheme()

  const styles: Styles = {
    cookies: {
      marginBottom: `${theme.spacing(2)} !important`,
      '& #cookie-policy-description, p, a, span, th, td': {
        fontFamily: `${theme.typography.fontFamily} !important`,
        fontSize: `${theme.typography.body2.fontSize} !important`,
        lineHeight: `${theme.typography.body2.lineHeight} !important`,
        color: `${theme.palette.text.secondary} !important`,
        marginBottom: `${theme.spacing(2)} !important`,
      },
      '& #cookie-policy-title, h4': {
        fontFamily: `${theme.typography.h2.fontFamily} !important`,
        color: `${theme.palette.text.primary} !important`,
        marginBottom: `${theme.spacing(2)} !important`,
      },
      '& #cookie-policy-title': {
        marginTop: `0 !important`,
        fontSize: `${theme.typography.h2.fontSize} !important`,
        fontWeight: `${theme.typography.h2.fontWeight} !important`,
      },
      '& h4': {
        marginTop: '3rem',
        fontSize: `${theme.typography.h3.fontSize} !important`,
        fontWeight: `${theme.typography.h3.fontWeight} !important`,
        textTransform: 'uppercase !important',
      },
      '& table': {
        marginTop: `${theme.spacing(5)} !important`,
        borderColor: `#E6E8ED !important`,
      },
      '& th': {
        fontSize: `12px !important`,
        fontWeight: `600 !important`,
        textTransform: 'uppercase !important',
        backgroundColor: `#E6E8ED !important`,
      },
      '& td, td span, td a': {
        fontSize: `${theme.typography.body2.fontSize} !important`,
        fontWeight: `${theme.typography.body2.fontWeight} !important`,
      },
      '& td a': {
        color: `${theme.palette.primary.main} !important`,
        fontWeight: `700 !important`,
      },
      '& #cookie-policy-description': {
        marginBottom: `${theme.spacing(2)} !important`,
      },
      '& #ot-sdk-cookie-policy-v2': {
        padding: `0 !important`,
      },
    },
  }

  useEffect(() => {
    const windowWithOneTrust: WindowWithOneTrust = window
    windowWithOneTrust?.OneTrust?.initializeCookiePolicyHtml()
  }, [])

  return (
    <Container sx={WEB_CONTAINER_VERTICAL_SPACE}>
      <Box sx={styles.cookies}>
        <div id="ot-sdk-cookie-policy" />
      </Box>
      <Box>
        <Button
          onClick={showCookieInfoPopup}
          color="primary"
          variant="outlined"
          endIcon={<ArrowForward sx={RTLIconStyles} />}
        >
          {t('cookiesSettings')}
        </Button>
      </Box>
    </Container>
  )
}

export default OneTrustCookieManager
