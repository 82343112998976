import { useEffect, useRef, useState } from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { LOGO_ANALYTICS_TARGET, LOGO_CLICK_INITIAL_PAYLOAD } from '@cms/analytics/constants'
import { getPageSlugFromRouterPath } from '@cms/analytics/utils'
import PreviewAlert from '@cms/components/PreviewAlert'
import type { PageHeaderProps } from '@components/Layout/Page/Page.types'
import { MiniHeader } from '@knauf-group/ct-designs/components/core/MiniHeader'
import { WebHeader } from '@knauf-group/ct-shared-nextjs/components/WebHeader'
import { useClickEvent } from '@knauf-group/ct-shared-nextjs/web/hooks'

export const PageHeader: React.FC<PageHeaderProps> = ({
  isPageInPreviewMode,
  headerEntries,
  appsConfig,
  isMiniHeader = false,
}) => {
  const router = useRouter()
  const { push: pushClickEvent } = useClickEvent()

  const { locale } = router
  const previewComponentRef = useRef<HTMLDivElement>(null)
  const [headerStickyTopValue, setHeaderStickyTopValue] = useState(0)

  const analyticsPageName = getPageSlugFromRouterPath(router)

  const onClickLogo = () => {
    pushClickEvent?.({
      ...LOGO_ANALYTICS_TARGET,
      eventPayload: {
        ...LOGO_CLICK_INITIAL_PAYLOAD,
        page_name: analyticsPageName,
      },
    })
    const targetRoute = locale === 'default' ? '/en' : '/'
    router.push(targetRoute)
  }

  useEffect(() => {
    function updateHeaderPosition() {
      if (previewComponentRef.current) {
        const previewComponentHeight = previewComponentRef.current.clientHeight
        setHeaderStickyTopValue(previewComponentHeight)
      } else {
        setHeaderStickyTopValue(0)
      }
    }

    window.addEventListener('resize', updateHeaderPosition)
    updateHeaderPosition()
    return () => window.removeEventListener('resize', updateHeaderPosition)
  }, [isPageInPreviewMode])

  return (
    <>
      {isPageInPreviewMode && <PreviewAlert ref={previewComponentRef} />}
      {isMiniHeader ? (
        <MiniHeader
          onLogoClick={onClickLogo}
          sx={{
            top: headerStickyTopValue,
          }}
        />
      ) : (
        <WebHeader
          appsConfig={appsConfig}
          navigationLinks={headerEntries}
          nextRouter={router}
          nextLinkComponent={NextLink}
          analytics={{ eventPayload: { page_name: analyticsPageName } }}
          onClickLogo={onClickLogo}
          sx={{
            top: headerStickyTopValue,
          }}
        />
      )}
    </>
  )
}
